import { missingMandatoryProp, missingProps } from "main/utils/errors";
import React from "react";
import ServicesFilter from "./_Services";
function ServicesFilterVariant2({ allServices, handleServiceClick }) {
    return (React.createElement("select", { multiple: true }, allServices.map((service) => (React.createElement("option", { key: service.id, onClick: () => handleServiceClick(service.id) }, service.name)))));
}
function Component(props) {
    return React.createElement(ServicesFilter, { ServicesFilterVariant: ServicesFilterVariant2, ...props });
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => {
        const componentName = "ServicesFilter (variant 1)";
        if (!props)
            throw new Error(missingProps(componentName));
        if (!props.allServices)
            throw new Error(missingMandatoryProp(componentName, "allServices"));
        if (!props.filter)
            throw new Error(missingMandatoryProp(componentName, "filter"));
        if (!props.setFilter)
            throw new Error(missingMandatoryProp(componentName, "setFilter"));
    },
};
