import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { TRAVEL_MODE } from "types/directions";
import { Autocomplete } from "@retailtune/react-ui-core";
import { createAutocompleteHandler, retailTuneAutocompleteHandler } from "@retailtune/utils";
import { googleAutocompleteHandler } from "@retailtune/google-maps-utils";
import { GeolocationErrors } from "main/utils/errors";
import { createPosition } from "main/utils/position";
import { analyticsHandler } from "core/events";
export function DirectionsPane({ allStores, directions, clearDirections, setTravelMode, setOrigin, language, translations, retailtuneKey }) {
    var _a, _b;
    const [autocompleteValue, setAutocompleteValue] = useState("");
    useEffect(() => {
        if (!directions.store)
            setAutocompleteValue("");
    }, [directions.store]);
    const countries = useMemo(() => {
        return Array.from(new Set(allStores.map(s => s.country.tagISO31661Alpha2)));
    }, [allStores]);
    const searchHandler = useCallback(createAutocompleteHandler(retailTuneAutocompleteHandler(retailtuneKey, {
        language,
        countries
    }), googleAutocompleteHandler()), [countries]);
    const predictionClickHandler = useCallback((prediction) => {
        if (!(prediction.latitude && prediction.longitude)) {
            throw new Error(GeolocationErrors.BAD_PREDICTION);
        }
        analyticsHandler.dispatch({
            type: "free_search",
            subtype: "directions",
            payload: { search: prediction.description },
        });
        setOrigin(createPosition({ latitude: prediction.latitude, longitude: prediction.longitude }));
    }, []);
    const autocompletePlaceholder = translations.autocomplete.placeholder;
    const originText = translations.yourAddress;
    return (React.createElement("div", { className: classNames("rt-directions-pane", {
            "rt-directions-pane--visible": directions.store,
        }) },
        React.createElement("img", { className: "rt-directions-pane__close-button", src: "/data/assets/close.svg", onClick: clearDirections }),
        React.createElement("ul", { className: "rt-directions-pane__travel-modes" },
            React.createElement("li", { className: classNames("", {
                    "rt-directions-pane__travel-mode--selected": directions.travelMode === TRAVEL_MODE.WALKING,
                }) },
                React.createElement("img", { className: "rt-directions-pane__travel-mode", onClick: () => setTravelMode(TRAVEL_MODE.WALKING), src: "/data/assets/walk.svg", alt: "walking" })),
            React.createElement("li", { className: classNames("", {
                    "rt-directions-pane__travel-mode--selected": directions.travelMode === TRAVEL_MODE.DRIVING,
                }) },
                React.createElement("img", { className: "rt-directions-pane__travel-mode", onClick: () => setTravelMode(TRAVEL_MODE.DRIVING), src: "/data/assets/car.svg", alt: "driving" })),
            React.createElement("li", { className: classNames("", {
                    "rt-directions-pane__travel-mode--selected": directions.travelMode === TRAVEL_MODE.PUBLIC_TRANSPORT,
                }) },
                React.createElement("img", { className: "rt-directions-pane__travel-mode", onClick: () => setTravelMode(TRAVEL_MODE.PUBLIC_TRANSPORT), src: "/data/assets/train.svg", alt: "bus" }))),
        React.createElement("div", { className: "rt-directions-pane__travel" },
            React.createElement(Autocomplete, { value: autocompleteValue, setValue: setAutocompleteValue, searchHandler: searchHandler, predictionClickHandler: predictionClickHandler, 
                // zeroResultsMessage="TODO zero results message"
                zeroResultsMessage: translations.autocomplete.zeroResultsMessage, placeholder: autocompletePlaceholder }),
            React.createElement("input", { disabled: true, className: "rt-directions-pane__travel-destination", placeholder: (_a = directions.store) === null || _a === void 0 ? void 0 : _a.address1 })),
        React.createElement("p", { className: "rt-directions-pane__instructions-start" }, originText),
        React.createElement("ol", { className: "rt-directions-pane__instructions" }, directions.directionsInfo &&
            directions.directionsInfo.steps.map((s, i) => (React.createElement("li", { key: i, className: "rt-directions-pane__instruction", dangerouslySetInnerHTML: {
                    __html: s,
                } })))),
        React.createElement("p", { className: "rt-directions-pane__instructions-end" }, (_b = directions.store) === null || _b === void 0 ? void 0 : _b.address1)));
}
