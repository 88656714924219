import { ExpirationType } from "types/time-expiration";
export class ExpirationTime {
    constructor(_value, _type) {
        this._value = _value;
        this._type = _type;
    }
    get value() {
        return this._value;
    }
    get type() {
        return this._type;
    }
    timeInMillis() {
        switch (this.type) {
            case ExpirationType.MINUTES: {
                return Date.now() + this.value * 60 * 1000;
            }
            case ExpirationType.HOURS: {
                return Date.now() + this.value * 60 * 60 * 1000;
            }
            case ExpirationType.DAYS: {
                return Date.now() + this.value * 24 * 60 * 60 * 1000;
            }
            case ExpirationType.MONTHS: {
                return Date.now() + this.value * 30 * 24 * 60 * 60 * 1000;
            }
            case ExpirationType.YEARS: {
                return Date.now() + this.value * 12 * 30 * 24 * 60 * 60 * 1000;
            }
            default: {
                throw new Error(`Expiration type "${this.type}" is not valid`);
            }
        }
    }
}
