import { createElement, Fragment } from "react";
import { isNode, isNodes } from "types/Representation";
import { v4 as uuidv4 } from "uuid";
async function dynamicImport(repr) {
    var _a, _b;
    const template = (_a = repr.template) !== null && _a !== void 0 ? _a : "";
    const path = (_b = repr.partialPath) !== null && _b !== void 0 ? _b : [];
    path.push(`${repr.name}${template}`);
    return await import(
    /* webpackChunkName: "[request]" */
    /* webpackMode: "eager" */
    // `../components/dynamic/${path}${repr.name}${template}`
    "../components/dynamic/" + path.filter(s => s).join('/'));
}
export async function parseNode(repr) {
    repr.key = uuidv4();
    const dynamicModule = await dynamicImport(repr);
    repr.dynamicComponent = dynamicModule.dynamicComponent;
    if (!repr.children)
        return;
    return await parseRepresentation(repr.children);
}
export async function parseNodes(repr) {
    for (let i = 0; i < repr.length; ++i) {
        await parseNode(repr[i]);
    }
}
export async function parseRepresentation(repr) {
    if (isNodes(repr)) {
        return await parseNodes(repr);
    }
    if (isNode(repr)) {
        return await parseNode(repr);
    }
    throw new Error("* PARSING ERROR - Representation type is not supported");
}
function addExternalResources(node, externalResources, apiKeys) {
    var _a;
    if (!((_a = node.dynamicComponent) === null || _a === void 0 ? void 0 : _a.externalResources))
        return;
    node.dynamicComponent.externalResources(apiKeys).forEach((res) => {
        const resource = externalResources.find((r) => r.type === res.type);
        if (!resource)
            externalResources.push(res);
    });
}
export function findExternalResources(repr, externalResources, apiKeys) {
    if (isNodes(repr)) {
        return repr.forEach((r) => findExternalResources(r, externalResources, apiKeys));
    }
    addExternalResources(repr, externalResources, apiKeys);
    if (repr.children) {
        return findExternalResources(repr.children, externalResources, apiKeys);
    }
}
function loadNode(repr, configuration, props) {
    var _a;
    if (!repr.dynamicComponent)
        throw new Error(`* LOADING ERROR: Missing Component for node ${repr.name}`);
    if (!repr.dynamicComponent.checkExpectedProps)
        throw new Error(`* LOADING ERROR: Missing 'checkExpectedProps' method for node ${repr.name}`);
    // preparing props for the node
    let nodeProps = {
        ...props,
        anchorPoints: (_a = props === null || props === void 0 ? void 0 : props.anchorPoints) !== null && _a !== void 0 ? _a : {},
        configuration
    };
    // applying transformation function on configuration if exists
    if (props && repr.dynamicComponent.transformConfiguration) {
        nodeProps.configuration = repr.dynamicComponent.transformConfiguration(configuration);
    }
    // applying filter function to props if exists
    if (props && repr.dynamicComponent.filterProps) {
        nodeProps = repr.dynamicComponent.filterProps(nodeProps);
    }
    const Component = repr.dynamicComponent.Component;
    if (!repr.children) {
        // leaf node
        repr.dynamicComponent.checkExpectedProps(nodeProps);
        return createElement(Component, nodeProps);
    }
    if (repr.dynamicComponent.children) {
        // dynamic node
        nodeProps.children = repr.dynamicComponent.children(repr.children, configuration);
        repr.dynamicComponent.checkExpectedProps(nodeProps);
        return createElement(Component, nodeProps);
    }
    // checking expected props for the component
    repr.dynamicComponent.checkExpectedProps(nodeProps);
    // static node - single & multiple elements
    return createElement(Component, nodeProps, loadRepresentation(repr.children, configuration, props));
}
function loadNodes(repr, configuration, props) {
    return createElement(Fragment, {}, repr.map((r) => loadNode(r, configuration, props)));
}
export function loadRepresentation(repr, configuration, props) {
    if (isNodes(repr)) {
        return loadNodes(repr, configuration, props);
    }
    if (isNode(repr)) {
        return loadNode(repr, configuration, props);
    }
    throw new Error("* LOADING ERROR: Representation type is not supported");
}
