import React, { useEffect, useRef } from "react";
import { GridAlgorithm, MarkerClusterer } from "@googlemaps/markerclusterer";
import { debounce, getDebounce } from "core/main";
import { analyticsHandler, centerChangedHandler, directionsRequestHandler, filteredStoresChangedHandler, zoomChangedHandler, } from "core/events";
import { TRAVEL_MODE } from "types/directions";
import { createPosition, positionToLatLng } from "utils/position";
let map;
// all markers
let markers = [];
// markers cache
let markersMap;
let markercluster;
// directions service for driving directions
let directionsService;
// directions renderer for driving directions
let directionsRenderer;
let destinationMarker;
let infoWindow;
// directions service request debounce function
const directionsDebounce = getDebounce(300);
// --- EVENTS
var MAP_EVENT;
(function (MAP_EVENT) {
    MAP_EVENT["BOUNDS_CHANGED"] = "bounds_changed";
    MAP_EVENT["CENTER_CHANGED"] = "center_changed";
    MAP_EVENT["ZOOM_CHANGED"] = "zoom_changed";
})(MAP_EVENT || (MAP_EVENT = {}));
// class CustomRenderer implements Renderer {
//   constructor(private readonly clusterIcon: string, private readonly clusterLabelColor: string) {}
//   render({ count, position }: Cluster): google.maps.Marker {
//     return new google.maps.Marker({
//       position,
//       label: {
//         text: count.toString(),
//         color: this.clusterLabelColor,
//       },
//       icon: {
//         url: this.clusterIcon,
//         scaledSize: new google.maps.Size(45, 45),
//       },
//     });
//   }
// }
// type MapHandlers = { [k: string]: (...args: any) => void };
class CustomRenderer {
    constructor(clusterIcon, clusterLabelColor) {
        this.clusterIcon = clusterIcon;
        this.clusterLabelColor = clusterLabelColor;
    }
    getClusterSize(markersCount) {
        if (markersCount < 10)
            return new google.maps.Size(40, 40);
        if (markersCount < 50)
            return new google.maps.Size(50, 50);
        if (markersCount < 100)
            return new google.maps.Size(60, 60);
        return new google.maps.Size(70, 70);
    }
    render({ count, position }) {
        return new google.maps.Marker({
            position,
            label: {
                text: count.toString(),
                color: this.clusterLabelColor,
            },
            icon: {
                url: this.clusterIcon,
                scaledSize: this.getClusterSize(count),
            },
        });
    }
}
function createMap(target, center, configuration) {
    var _a, _b, _c;
    if (map)
        return;
    const boundsRestriction = configuration.boundsRestriction
        ? {
            latLngBounds: new google.maps.LatLngBounds(new google.maps.LatLng(configuration.boundsRestriction.sw.latitude, configuration.boundsRestriction.sw.longitude), new google.maps.LatLng(configuration.boundsRestriction.ne.latitude, configuration.boundsRestriction.ne.longitude)),
        }
        : undefined;
    map = new google.maps.Map(target, {
        center,
        zoom: 8,
        restriction: boundsRestriction,
        mapTypeControl: (_a = configuration.controls) === null || _a === void 0 ? void 0 : _a.mapTypeControl,
        streetViewControl: (_b = configuration.controls) === null || _b === void 0 ? void 0 : _b.streetViewControl,
        fullscreenControl: (_c = configuration.controls) === null || _c === void 0 ? void 0 : _c.fullScreenControl,
        styles: configuration.styles,
    });
    markersMap = {
        user: new google.maps.Marker({
            map,
            position: center,
            ...(configuration.userMarkerUrl && { icon: configuration.userMarkerUrl }),
        }),
        pois: {},
    };
    const algorithm = configuration.markerCluster.algorithm === "grid" ? new GridAlgorithm({}) : undefined; // default == supercluster algorithm
    markercluster = new MarkerClusterer({
        map,
        markers,
        algorithm,
        ...(configuration.markerCluster && {
            renderer: new CustomRenderer(configuration.markerCluster.iconUrl, configuration.markerCluster.labelColor),
        }),
    });
    directionsService = new google.maps.DirectionsService();
    directionsRenderer = new google.maps.DirectionsRenderer({
        markerOptions: {
            visible: false,
        },
        polylineOptions: {
            ...(configuration.directions.polyline && {
                strokeColor: configuration.directions.polyline.color,
            }),
            ...(configuration.directions.polyline && {
                strokeWeight: configuration.directions.polyline.weight,
            }),
        },
    });
}
function handleMapCenterChanged(handler) {
    const center = map.getCenter();
    const bounds = map.getBounds();
    if (!(center && bounds))
        return;
    const mapCenter = createPosition({
        latitude: center.lat(),
        longitude: center.lng(),
    });
    handler.dispatch({
        mapCenter,
        containsCallback: store => {
            return bounds.contains({ lat: store.latitude, lng: store.longitude });
        },
    });
}
function handleMapZoomChanged(handler) {
    const mapCenter = map.getCenter();
    const mapZoom = map.getZoom();
    const bounds = map.getBounds();
    if (!(mapCenter && mapZoom && bounds))
        return;
    // console.log(`Zoom changed: ${mapZoom}`);
    handler.dispatch({
        mapCenter: createPosition({ latitude: mapCenter.lat(), longitude: mapCenter.lng() }),
        mapZoom,
        containsCallback: store => {
            return bounds.contains({ lat: store.latitude, lng: store.longitude });
        },
    });
}
function updateMap(center, mapZoom) {
    if (!map)
        return;
    map.setCenter(center);
    map.setZoom(mapZoom);
    markersMap.user.setPosition(center);
}
function getTravelMode(travelMode) {
    switch (travelMode) {
        case TRAVEL_MODE.WALKING:
            return google.maps.TravelMode.WALKING;
        case TRAVEL_MODE.DRIVING:
            return google.maps.TravelMode.DRIVING;
        case TRAVEL_MODE.PUBLIC_TRANSPORT:
            return google.maps.TravelMode.TRANSIT;
        default:
            return google.maps.TravelMode.DRIVING;
    }
}
var PROPS;
(function (PROPS) {
    PROPS["ANCHOR_POINTS"] = "anchorPoints";
    PROPS["MAP_ZOOM"] = "mapZoom";
    PROPS["CONFIGURATION"] = "configuration";
    PROPS["CURRENT_POSITION"] = "currentPosition";
    PROPS["FILTERED_STORES"] = "filteredStores";
    PROPS["DIRECTIONS_ORIGIN"] = "directionsOrigin";
    PROPS["DESTINATION_STORE"] = "destinationStore";
    PROPS["TRAVEL_MODE"] = "travelMode";
    PROPS["SELECTED_STORE"] = "selectedStore";
    PROPS["SET_SELECTED_STORE"] = "setSelectedStore";
    PROPS["SET_DIRECTIONS_STORE"] = "setDirectionsStore";
    PROPS["SET_DIRECTIONS_INFO"] = "setDirectionsInfo";
})(PROPS || (PROPS = {}));
function Component({ configuration, mapZoom, currentPosition, filteredStores, directionsOrigin, destinationStore, travelMode, selectedStore, setSelectedStore, setDirectionsStore, setDirectionsInfo, }) {
    const mapRef = useRef(null);
    useEffect(() => {
        if (map) {
            map.setZoom(mapZoom);
            // console.log(`useEffect triggered with zoom ${mapZoom}`);
        }
    }, [mapZoom, map]);
    useEffect(() => {
        if (map) {
            function dispatch() {
                const bounds = map.getBounds();
                const center = map.getCenter();
                if (bounds && center) {
                    filteredStoresChangedHandler.dispatch({
                        mapCenter: createPosition({ latitude: center.lat(), longitude: center.lng() }),
                        containsCallback: store => {
                            return bounds.contains({ lat: store.latitude, lng: store.longitude });
                        },
                    });
                    clearInterval(i);
                }
            }
            const i = setInterval(dispatch, 100);
        }
    }, [map, filteredStores]);
    useEffect(() => {
        let centerChangedMapsEventListener;
        let zoomChangedMapsEventListener;
        const centerChangedListener = debounce(300, handleMapCenterChanged, centerChangedHandler);
        const zoomChangedListener = debounce(300, handleMapZoomChanged, zoomChangedHandler);
        if (map) {
            centerChangedMapsEventListener = map.addListener(MAP_EVENT.CENTER_CHANGED, centerChangedListener);
            zoomChangedMapsEventListener = map.addListener(MAP_EVENT.ZOOM_CHANGED, zoomChangedListener);
        }
        return () => {
            if (centerChangedMapsEventListener)
                centerChangedMapsEventListener.remove();
            if (zoomChangedMapsEventListener)
                zoomChangedMapsEventListener.remove();
        };
    }, [map]);
    useEffect(() => {
        const center = positionToLatLng(currentPosition);
        map ? updateMap(center, mapZoom) : createMap(mapRef.current, center, configuration);
    }, [currentPosition, mapZoom]);
    useEffect(() => {
        function renderMarkers() {
            var _a;
            markers = [];
            markercluster.clearMarkers();
            const markerIcons = Object.entries(configuration.storeMarkerUrls);
            for (let i = 0; i < filteredStores.length; ++i) {
                const store = filteredStores[i];
                if (markersMap.pois[store.storeCode]) {
                    markers.push(markersMap.pois[store.storeCode]);
                    continue;
                }
                const position = positionToLatLng(createPosition({
                    latitude: store.latitude,
                    longitude: store.longitude,
                }));
                const icon = (_a = markerIcons.find(([storeType, _]) => {
                    return store.storeTypeLabels.includes(storeType);
                })) === null || _a === void 0 ? void 0 : _a[1];
                const marker = new google.maps.Marker({
                    map,
                    position,
                    icon,
                });
                marker.addListener("click", () => {
                    getInfoWindow(store).open({
                        anchor: marker,
                        map,
                        shouldFocus: true,
                    });
                    map.setCenter(position);
                    //   infoWindowClickHandler.dispatch({
                    //     activeStore: store.storeCode,
                    //   });
                });
                markers.push(marker);
                markersMap.pois[store.storeCode] = marker;
            }
            markercluster.addMarkers(markers);
        }
        if (!destinationStore) {
            renderMarkers();
        }
    }, [filteredStores, destinationStore]);
    useEffect(() => {
        function _handleDirectionsChange() {
            if (!map)
                return;
            if (!destinationStore) {
                // clear directions
                directionsRenderer.setDirections(null);
                markersMap.user.setPosition(positionToLatLng(currentPosition));
                if (destinationMarker)
                    destinationMarker.setMap(null);
                return;
            }
            // prepare map for directions
            const destination = positionToLatLng(createPosition({
                latitude: destinationStore.latitude,
                longitude: destinationStore.longitude,
            }));
            const request = {
                origin: positionToLatLng(directionsOrigin),
                destination,
                travelMode: getTravelMode(travelMode),
            };
            directionsService.route(request, (directionsResult, directionsStatus) => {
                var _a;
                if (!directionsResult) {
                    // console.error(DirectionsErrors.NULL_RESULT);
                    // return toast(RuntimeData.translations.warnings.directions.genericError);
                    return;
                }
                if (directionsStatus === "ZERO_RESULTS") {
                    // console.error(DirectionsErrors.ZERO_RESULTS);
                    // return toast(RuntimeData.translations.warnings.directions.noResults);
                    return;
                }
                if (directionsStatus === "OK") {
                    const markerIcons = Object.entries(configuration.storeMarkerUrls);
                    const icon = (_a = markerIcons.find(([storeType, _]) => {
                        return destinationStore.storeTypeLabels.includes(storeType);
                    })) === null || _a === void 0 ? void 0 : _a[1];
                    directionsRequestHandler.dispatch({
                        destinationStore,
                        onDirectionsReady: () => {
                            var _a, _b, _c, _d;
                            // console.log(directionsResult);
                            const path = directionsResult.routes[0].legs[0];
                            const directionsInfo = {
                                steps: path.steps.map(s => s.instructions),
                                time: (_b = (_a = path.duration) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : "",
                                distance: (_d = (_c = path.distance) === null || _c === void 0 ? void 0 : _c.text) !== null && _d !== void 0 ? _d : "",
                            };
                            setDirectionsInfo(directionsInfo);
                            directionsRenderer.setMap(map);
                            directionsRenderer.setDirections(directionsResult);
                            markercluster.removeMarkers(markers);
                            markersMap.user.setPosition(positionToLatLng(directionsOrigin));
                            if (!destinationMarker) {
                                destinationMarker = new google.maps.Marker({ map, position: destination, icon });
                            }
                            else {
                                destinationMarker.setOptions({ map, position: destination, icon });
                            }
                        },
                    });
                }
            });
        }
        directionsDebounce(_handleDirectionsChange)();
    }, [map, currentPosition, destinationStore, directionsOrigin, travelMode]);
    function getInfoWindow(store) {
        if (!infoWindow) {
            infoWindow = new google.maps.InfoWindow();
        }
        const body = document.createElement("div");
        body.classList.add("rt-info-window");
        const name = document.createElement("h3");
        name.classList.add("rt-info-window__name");
        name.innerText = store.name;
        const address = document.createElement("p");
        address.classList.add("rt-info-window__address");
        address.innerText = store.address1;
        const city = document.createElement("p");
        city.classList.add("rt-info-window__city");
        city.innerText = `${store.postalCode}, ${store.city}`;
        const buttons = document.createElement("div");
        buttons.classList.add("rt-info-window__buttons");
        const storeDetailsButton = document.createElement("button");
        storeDetailsButton.classList.add("rt-info-window__button-details");
        storeDetailsButton.innerText = configuration === null || configuration === void 0 ? void 0 : configuration.translations.infoWindow.info;
        storeDetailsButton.onclick = (e) => {
            e.stopPropagation();
            analyticsHandler.dispatch({
                type: "click_store_details",
                subtype: "map",
                payload: { store },
            });
            window.open(store.storeLink);
        };
        const drivingDirectionsButton = document.createElement("button");
        drivingDirectionsButton.classList.add("rt-info-window__button-directions");
        drivingDirectionsButton.innerText = configuration === null || configuration === void 0 ? void 0 : configuration.translations.infoWindow.directions;
        drivingDirectionsButton.onclick = (e) => {
            e.stopPropagation();
            analyticsHandler.dispatch({
                type: "click_directions",
                subtype: "map",
                payload: { store },
            });
            setDirectionsStore(store);
        };
        buttons.appendChild(storeDetailsButton);
        buttons.appendChild(drivingDirectionsButton);
        body.appendChild(name);
        body.appendChild(address);
        body.appendChild(city);
        body.appendChild(buttons);
        infoWindow.setContent(body);
        return infoWindow;
    }
    return React.createElement("div", { ref: mapRef, className: "rt-map-google", style: { width: "600px", height: "100%" } });
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: props => {
        // const componentName = "GoogleMap";
        // if (!props) throw new Error(missingProps(componentName));
        // const mandatoryProps = Object.values(PROPS);
        // const actualProps = Object.keys(props);
        // mandatoryProps.forEach((p) => {
        //   if (!actualProps.includes(p)) {
        //     throw new Error(missingMandatoryProp(componentName, p));
        //   }
        // });
    },
    // filterProps: (props) => {
    //   if (!props) throw new Error(missingProps("GoogleMap"));
    //   const allowedProps = Object.values(PROPS);
    //   return getFilteredProps(props, ([propName, _]) => allowedProps.some((p) => p === propName));
    // },
    transformConfiguration: (config) => {
        return {
            ...config.map.google,
            translations: {
                infoWindow: config.translations.map.infoWindow,
            },
        };
    },
};
