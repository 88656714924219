import React, { useEffect, useRef } from "react";
import { loadRepresentation } from "core/parser";
import { missingMandatoryProp, missingProps } from "main/utils/errors";
import { scrollingHandler } from "core/events";
function Component({ children, visibleStores, ...rest }) {
    const listRef = useRef(null);
    useEffect(() => {
        var _a;
        function handleScroll(e) {
            const scrollingItem = listRef.current;
            const scrollingOffset = listRef.current.scrollTop;
            scrollingHandler.dispatch({ scrollingItem, scrollingOffset });
        }
        (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener("scroll", handleScroll);
        return () => { var _a; return (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener("scroll", handleScroll); };
    }, []);
    return (React.createElement("ul", { ref: listRef, className: "list-1" }, children(visibleStores, rest)));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => {
        const componentName = "List (variant 1)";
        if (!props)
            throw new Error(missingProps(componentName));
        if (!props.children)
            throw new Error(missingMandatoryProp(componentName, "children"));
        if (!props.visibleStores)
            throw new Error(missingMandatoryProp(componentName, "visibleStores"));
    },
    children: (repr, config) => (stores, props) => stores.map((store) => loadRepresentation(repr, config, { ...props, key: store.storeCode, store })),
};
