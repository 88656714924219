import React from "react";
import { loadRepresentation } from "core/parser";
import { missingMandatoryProp, missingProps } from "main/utils/errors";
import { analyticsHandler } from "core/events";
export function Component({ children, useUserPosition, configuration, destinationStore, resetDirections, ...rest }) {
    function handleNearestStoresButtonClick() {
        if (destinationStore && resetDirections) {
            resetDirections();
        }
        useUserPosition();
        analyticsHandler.dispatch({ type: "click_nearest_stores" });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: "rt-nearest-stores-button", onClick: handleNearestStoresButtonClick }, configuration.buttonText),
        children && children({ ...rest, useUserPosition, destinationStore, resetDirections })));
}
export const dynamicComponent = {
    Component,
    children: (repr, configuration) => (props) => loadRepresentation(repr, configuration, props),
    checkExpectedProps: (props) => {
        const componentName = "GeolocationButton";
        if (!props)
            throw new Error(missingProps(componentName));
        if (!props.useUserPosition)
            throw new Error(missingMandatoryProp(componentName, "useUserPosition"));
    },
    transformConfiguration: (config) => {
        return {
            buttonText: config.translations.header.geolocation.text,
        };
    },
};
