var ActionType;
(function (ActionType) {
    ActionType[ActionType["CLEAR_DIRECTIONS"] = 0] = "CLEAR_DIRECTIONS";
    ActionType[ActionType["UPDATE_USER_POSITION"] = 1] = "UPDATE_USER_POSITION";
    ActionType[ActionType["UPDATE_ORIGIN"] = 2] = "UPDATE_ORIGIN";
    ActionType[ActionType["UPDATE_STORE"] = 3] = "UPDATE_STORE";
    ActionType[ActionType["UPDATE_DIRECTIONS_INFO"] = 4] = "UPDATE_DIRECTIONS_INFO";
    ActionType[ActionType["UPDATE_TRAVEL_MODE"] = 5] = "UPDATE_TRAVEL_MODE";
})(ActionType || (ActionType = {}));
export function isClearDirectionsAction(action) {
    return action.type === ActionType.CLEAR_DIRECTIONS;
}
export function isUpdateUserPositionAction(action) {
    return action.type === ActionType.UPDATE_USER_POSITION;
}
export function isUpdateOriginAction(action) {
    return action.type === ActionType.UPDATE_ORIGIN;
}
export function isUpdateStoreAction(action) {
    return action.type === ActionType.UPDATE_STORE;
}
export function isUpdateDirectionsInfoAction(action) {
    return action.type === ActionType.UPDATE_DIRECTIONS_INFO;
}
export function isUpdateTravelModeAction(action) {
    return action.type === ActionType.UPDATE_TRAVEL_MODE;
}
export function clearDirections() {
    return {
        type: ActionType.CLEAR_DIRECTIONS,
        payload: {},
    };
}
export function updateUserPosition(userPosition) {
    return {
        type: ActionType.UPDATE_USER_POSITION,
        payload: { userPosition }
    };
}
export function updateOrigin(origin) {
    return {
        type: ActionType.UPDATE_ORIGIN,
        payload: { origin },
    };
}
export function updateStore(store) {
    return {
        type: ActionType.UPDATE_STORE,
        payload: { store },
    };
}
export function updateDirectionsInfo(directionsInfo) {
    return {
        type: ActionType.UPDATE_DIRECTIONS_INFO,
        payload: { directionsInfo },
    };
}
export function updateTravelMode(travelMode) {
    return {
        type: ActionType.UPDATE_TRAVEL_MODE,
        payload: { travelMode },
    };
}
