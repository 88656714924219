import { loadRepresentation } from "core/parser";
import { missingMandatoryProp, missingProps } from "main/utils/errors";
import React from "react";
function Component({ children, ...rest }) {
    return React.createElement("div", { className: "rt-filters-pane" }, children(rest));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => {
        const componentName = "FiltersArea";
        if (!props)
            throw new Error(missingProps(componentName));
        if (!props.filter)
            throw new Error(missingMandatoryProp(componentName, "filter"));
        if (!props.setFilter)
            throw new Error(missingMandatoryProp(componentName, "setFilter"));
    },
    children: (repr, config) => (props) => loadRepresentation(repr, config, props),
};
