/**
 * EVENT is the enumeration used to list all the possible custom event that can be
 * fired and listened to
 */
export var EVENT;
(function (EVENT) {
    EVENT["ANALYTICS_EVENT"] = "analyticsEvent";
    EVENT["INFO_WINDOW_CLICK"] = "info_window_click";
    EVENT["MAP_CENTER_CHANGED"] = "map_center_changed";
    EVENT["MAP_DIRECTIONS_REQUEST"] = "map_directions_request";
    EVENT["MAP_ZOOM_CHANGED"] = "map_zoom_changed";
    EVENT["STORE_CARD_CLICK"] = "store_card_click";
    EVENT["FILTERED_STORES_CHANGED"] = "filtered_stores_changed";
    EVENT["ELEMENT_IS_SCROLLING"] = "element_is_scrolling";
})(EVENT || (EVENT = {}));
/**
 * EventHandler is used both as a target and a dispatcher for custom events needed by the
 * application. Bound to an event, it exposes an API that let the user take advantage of the
 * underlying EventTarget methods to handle event listeners
 */
export class EventHandler extends EventTarget {
    constructor(eventType) {
        super();
        this.eventType = eventType;
    }
    addListener(listener) {
        // add a listeners on this
        this.addEventListener(this.eventType, listener);
    }
    dispatch(eventDetail) {
        // uses this to dispatch a custom event
        const evt = new CustomEvent(this.eventType, { detail: eventDetail });
        this.dispatchEvent(evt);
    }
    removeListener(listener) {
        // removes a listener form this
        this.removeEventListener(this.eventType, listener);
    }
}
export const centerChangedHandler = new EventHandler(EVENT.MAP_CENTER_CHANGED);
export const zoomChangedHandler = new EventHandler(EVENT.MAP_ZOOM_CHANGED);
export const directionsRequestHandler = new EventHandler(EVENT.MAP_DIRECTIONS_REQUEST);
export const storeCardClickHandler = new EventHandler(EVENT.STORE_CARD_CLICK);
export const infoWindowClickHandler = new EventHandler(EVENT.INFO_WINDOW_CLICK);
export const filteredStoresChangedHandler = new EventHandler(EVENT.FILTERED_STORES_CHANGED);
export const scrollingHandler = new EventHandler(EVENT.ELEMENT_IS_SCROLLING);
// analytics handler is in charge of firing and listening to Analytics related events
export const analyticsHandler = new EventHandler(EVENT.ANALYTICS_EVENT);
