// mixin for the version of the autocomplete with the clear button
import { Autocomplete } from "@retailtune/react-ui-core";
import { loadRepresentation } from "core/parser";
import React from "react";
function Component({ children, autocomplete, ...rest }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Autocomplete, { ...autocomplete }),
        children && children(rest)));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => {
    },
    children: (repr, config) => (props) => loadRepresentation(repr, config, props),
};
