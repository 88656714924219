import classNames from "classnames";
import React from "react";
import { missingMandatoryProp, missingProps } from "main/utils/errors";
import { getFilteredProps } from "core/main";
function Component({ children, selectedDistance, setSelectedDistance }) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "rt-radius-buttons" },
            React.createElement("button", { className: classNames("rt-radius-button", {
                    "rt-radius-button--selected": selectedDistance === 5,
                }), onClick: () => setSelectedDistance(5) }, "5 Km"),
            React.createElement("button", { className: classNames("rt-radius-button", {
                    "rt-radius-button--selected": selectedDistance === 10,
                }), onClick: () => setSelectedDistance(10) }, "10 Km"),
            React.createElement("button", { className: classNames("rt-radius-button", {
                    "rt-radius-button--selected": selectedDistance === 50,
                }), onClick: () => setSelectedDistance(50) }, "50 Km")),
        children));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => {
        const componentName = "RadiusBar";
        if (!props)
            throw new Error(missingProps(componentName));
        if (!props.selectedDistance) {
            throw new Error(missingMandatoryProp(componentName, "selectedDistance"));
        }
        if (!props.setSelectedDistance) {
            throw new Error(missingMandatoryProp(componentName, "setSelectedDistance"));
        }
    },
    filterProps: (props) => {
        if (!props)
            throw new Error(missingProps("RadiusBar"));
        const neededProps = ["selectedDistance", "setSelectedDistance"];
        return getFilteredProps(props, ([propName, _]) => neededProps.some((p) => p === propName));
    }
};
