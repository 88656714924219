import React from "react";
export default function ServicesFilter({ ServicesFilterVariant, filter, setFilter, ...rest }) {
    function handleServiceClick(serviceId) {
        var _a, _b, _c, _d;
        const services = ((_a = filter.services) === null || _a === void 0 ? void 0 : _a.includes(serviceId))
            ? (_b = filter.services) === null || _b === void 0 ? void 0 : _b.filter((id) => id !== serviceId)
            : (_d = (_c = filter.services) === null || _c === void 0 ? void 0 : _c.concat([serviceId])) !== null && _d !== void 0 ? _d : [serviceId];
        setFilter((prev) => ({
            ...prev,
            services,
        }));
    }
    return React.createElement(ServicesFilterVariant, { ...rest, filter, setFilter, handleServiceClick });
}
