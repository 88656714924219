import React from "react";
import { saveExpirable } from "utils/local-storage";
import { LOCAL_STORAGE_USER_POSITION_CONSENT } from "utils/local-storage";
import { ExpirationTime } from "utils/time-expiration";
import { ExpirationType } from "types/time-expiration";
import "./ConsentModal.css";
import { analyticsHandler } from "core/events";
export default function ConsentModal({ translations: { title, disclaimer, yes, no }, setModalVisibility, setUserConsent, }) {
    function handleUserConsent(consent) {
        if (consent) {
            setUserConsent(consent);
            const userConsentData = {
                consent,
                expiration: new ExpirationTime(1, ExpirationType.YEARS).timeInMillis(),
            };
            saveExpirable(LOCAL_STORAGE_USER_POSITION_CONSENT, userConsentData);
        }
        setModalVisibility(false);
        analyticsHandler.dispatch({ type: "geolocation_consent", subtype: consent });
    }
    return (React.createElement("div", { id: "consent_modal", className: "rt-consent-modal-overlay" },
        React.createElement("div", { className: "rt-consent-modal-popup" },
            React.createElement("p", { className: "rt-consent-modal-popup__title" }, title),
            React.createElement("p", { className: "rt-consent-modal-popup__disclaimer" }, disclaimer),
            React.createElement("div", { className: "rt-consent-modal-popup__buttons" },
                React.createElement("button", { className: "rt-consent-modal-popup__button-yes", onClick: () => handleUserConsent(true) }, yes),
                React.createElement("button", { className: "rt-consent-modal-popup__button-no", onClick: () => handleUserConsent(false) }, no)))));
}
