import { missingMandatoryProp, missingProps } from "main/utils/errors";
import React from "react";
import ServicesFilter from "./_Services";
function ServicesFilterVariant1({ allServices, filter, handleServiceClick }) {
    return (React.createElement("ul", null, allServices.map((service) => {
        var _a;
        return (React.createElement("li", { key: service.id, onClick: () => handleServiceClick(service.id) },
            React.createElement("input", { type: "checkbox", checked: (_a = filter.services) === null || _a === void 0 ? void 0 : _a.includes(service.id) }),
            React.createElement("label", null, service.name)));
    })));
}
function Component(props) {
    return React.createElement(ServicesFilter, { ServicesFilterVariant: ServicesFilterVariant1, ...props });
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => {
        const componentName = "ServicesFilter (variant 1)";
        if (!props)
            throw new Error(missingProps(componentName));
        if (!props.allServices)
            throw new Error(missingMandatoryProp(componentName, "allServices"));
        if (!props.filter)
            throw new Error(missingMandatoryProp(componentName, "filter"));
        if (!props.setFilter)
            throw new Error(missingMandatoryProp(componentName, "setFilter"));
    },
};
