import { centerChangedHandler, filteredStoresChangedHandler, zoomChangedHandler, } from "core/events";
import { loadRepresentation } from "core/parser";
import { missingMandatoryProp, missingProps } from "main/utils/errors";
import { getDistanceFn } from "main/utils/position";
import { sortByDistance } from "main/utils/sort";
import React, { useEffect, useState } from "react";
import StoreLocator, { STORE_LOCATOR_PROPS, } from "./_StoreLocator";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function StoreLocatorVariant2({ hostname, currentDevice, autocomplete, mapZoom, setMapZoom, configuration, storeLocatorRef, allStores, allServices, servicesData: servicesData, anchorPoints, children, openingTimesCache, selectedStore, setSelectedStore, position: { position, setPosition, geolocateUser, useUserPosition }, directions: { resetDirections, origin, destinationStore, travelMode, setStore, setDirectionsInfo, setTravelMode, }, filters: { filter, setFilter }, }) {
    const [filteredStores, setFilteredStores] = useState([]);
    const [visibleStores, setVisibleStores] = useState([]);
    useEffect(() => {
        function getVisibleStores(mapCenter, cb) {
            const getDistance = getDistanceFn(mapCenter.latitude, mapCenter.longitude);
            const visibleStores = filteredStores.filter(cb);
            for (let i = 0; i < visibleStores.length; ++i) {
                visibleStores[i].distance = getDistance(visibleStores[i].latitude, visibleStores[i].longitude);
            }
            return visibleStores.sort(sortByDistance);
        }
        function centerChangedListener(e) {
            console.log("center changed");
            setVisibleStores(getVisibleStores(e.detail.mapCenter, e.detail.containsCallback));
        }
        function zoomChangedListener(e) {
            console.log("center changed");
            setVisibleStores(getVisibleStores(e.detail.mapCenter, e.detail.containsCallback));
        }
        function filteredStoresChangedListener(e) {
            console.log("filteredStores changed: ", e.detail);
            setVisibleStores(getVisibleStores(e.detail.mapCenter, e.detail.containsCallback));
        }
        centerChangedHandler.addListener(centerChangedListener);
        zoomChangedHandler.addListener(zoomChangedListener);
        filteredStoresChangedHandler.addListener(filteredStoresChangedListener);
        return () => {
            centerChangedHandler.removeListener(centerChangedListener);
            zoomChangedHandler.removeListener(zoomChangedListener);
            filteredStoresChangedHandler.removeListener(filteredStoresChangedListener);
        };
    }, [filteredStores]);
    useEffect(() => {
        const servicesPred = (s) => !filter.services || filter.services.every((serviceId) => s.services.includes(serviceId));
        const storeTypesPred = (s) => !filter.storeTypes ||
            filter.storeTypes.some((storeType) => s.storeTypes.includes(storeType));
        setFilteredStores(allStores.filter((s) => storeTypesPred(s) && servicesPred(s)));
    }, [filter]);
    return (React.createElement(React.Fragment, null, children({
        hostname,
        currentDevice,
        allStores,
        allServices,
        servicesData: servicesData,
        autocomplete,
        mapZoom,
        setMapZoom,
        anchorPoints,
        currentPosition: position.currentPosition,
        destinationStore,
        directionsOrigin: origin,
        filteredStores,
        filter,
        setFilter,
        geolocateUser,
        openingTimesCache,
        useUserPosition,
        resetDirections,
        setDirectionsStore: setStore,
        setDirectionsInfo,
        setTravelMode,
        setPosition,
        selectedStore,
        setSelectedStore,
        setFilteredStores,
        setVisibleStores,
        travelMode,
        visibleStores,
        userPosition: position.userPosition,
    })));
}
const Component = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(ToastContainer, { position: "top-right", pauseOnHover: true, autoClose: 3000 }),
        React.createElement(StoreLocator, { StoreLocatorVariant: StoreLocatorVariant2, ...props }),
        ";"));
};
export const dynamicComponent = {
    Component,
    children: (repr, configuration) => (props) => loadRepresentation(repr, configuration, props),
    checkExpectedProps: (props) => {
        const componentName = "StoreLocator (variant 2)";
        if (!props)
            throw new Error(missingProps(componentName));
        const mandatoryProps = Object.values(STORE_LOCATOR_PROPS).filter((v) => v !== STORE_LOCATOR_PROPS.CHILDREN);
        const actualProps = Object.keys(props);
        mandatoryProps.forEach((prop) => {
            if (!actualProps.includes(prop)) {
                throw new Error(missingMandatoryProp(componentName, prop));
            }
        });
    },
};
