// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rt-consent-modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  visibility: visible;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
}

.rt-consent-modal-popup {
  background: #fff;
  border-radius: 5px;
  position: fixed;
  transition: all 1s ease-in-out;
  padding: 18px;
  text-align: center;
}

.rt-consent-modal-popup__buttons {
  display: flex;
  gap: 10px;
}

.rt-consent-modal-popup button {
  text-align: center;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/consent-modal/ConsentModal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,UAAU;EACV,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,8BAA8B;EAC9B,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".rt-consent-modal-overlay {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  visibility: visible;\n  opacity: 1;\n  background: rgba(0, 0, 0, 0.5);\n}\n\n.rt-consent-modal-popup {\n  background: #fff;\n  border-radius: 5px;\n  position: fixed;\n  transition: all 1s ease-in-out;\n  padding: 18px;\n  text-align: center;\n}\n\n.rt-consent-modal-popup__buttons {\n  display: flex;\n  gap: 10px;\n}\n\n.rt-consent-modal-popup button {\n  text-align: center;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
