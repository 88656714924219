import React from "react";
import { loadRepresentation } from "core/parser";
import { missingProps } from "main/utils/errors";
export function Component({ children, hostname, servicesData, useUserPosition, configuration, destinationStore, resetDirections, ...rest }) {
    const serviceDataList = Object.entries(servicesData).map(([k, v]) => {
        return {
            label: k,
            ...v
        };
    });
    const kidsCollection = serviceDataList.find(s => s.id === 12);
    const handleKidsCollectionRedirect = () => {
        window.location.href = `${hostname}${configuration.lang}/${kidsCollection.label}`;
    };
    return (React.createElement(React.Fragment, null,
        kidsCollection && (React.createElement("button", { className: "rt-kids-collection-btn", onClick: handleKidsCollectionRedirect }, configuration.buttonText)),
        children && children({ ...rest, useUserPosition, destinationStore, resetDirections })));
}
export const dynamicComponent = {
    Component,
    children: (repr, configuration) => (props) => loadRepresentation(repr, configuration, props),
    checkExpectedProps: (props) => {
        const componentName = "KidsCollectionBtn";
        if (!props)
            throw new Error(missingProps(componentName));
    },
    transformConfiguration: (config) => {
        return {
            buttonText: config.translations.header.kidsCollection.text,
            lang: config.language
        };
    },
};
