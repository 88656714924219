import React from "react";
import { loadRepresentation } from "core/parser";
import { missingMandatoryProp, missingProps } from "main/utils/errors";
function Component({ children, visibleStores, configuration, ...rest }) {
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: "rt-stores-found" },
            visibleStores.length,
            " ",
            visibleStores.length === 1
                ? configuration.singleStoreFoundText
                : configuration.multipleStoresFoundText),
        children({ visibleStores, ...rest })));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => {
        const componentName = "StoresNumber";
        if (!props)
            throw new Error(missingProps(componentName));
        if (!props.children)
            throw new Error(missingMandatoryProp(componentName, "children"));
        if (!props.visibleStores)
            throw new Error(missingMandatoryProp(componentName, "visibleStores"));
    },
    children: (repr, config) => (props) => loadRepresentation(repr, config, props),
    transformConfiguration: (config) => {
        return {
            singleStoreFoundText: config.translations.list.storeFound,
            multipleStoresFoundText: config.translations.list.storesFound,
        };
    },
};
