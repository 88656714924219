// --- LOCAL STORAGE NAMES
export const LOCAL_STORAGE_USER_COOKIE_CONSENT = "consent-cookies";
export const LOCAL_STORAGE_USER_POSITION_CONSENT = "consent-user-position";
export const LOCAL_STORAGE_DEFAULT_POSITION = "default-position";
export const LOCAL_STORAGE_USER_POSIITON = "user-position";
export const LOCAL_STORAGE_CURRENT_POSITION = "current-position";
export function saveExpirable(name, expirable) {
    localStorage.setItem(name, JSON.stringify(expirable));
}
function isExpirable(obj) {
    return obj.expiration !== undefined;
}
function isExpired(expirable) {
    const now = new Date();
    const expiration = new Date(expirable.expiration);
    return now > expiration;
}
function getExpirableItem(name) {
    const item = localStorage.getItem(name);
    if (!item)
        return null;
    let parsedItem = JSON.parse(item);
    if (isExpirable(parsedItem) && !isExpired(parsedItem)) {
        return parsedItem;
    }
    return null;
}
function isConsentData(obj) {
    return isExpirable(obj) && obj.consent !== undefined;
}
export function getConsentData(name) {
    const expirable = getExpirableItem(name);
    if (!expirable) {
        return null;
    }
    if (!isConsentData(expirable)) {
        throw new TypeError("type mismatch");
    }
    return expirable.consent;
}
function isPositionData(obj) {
    return isExpirable(obj) && obj.position !== undefined;
}
export function getPositionData(name) {
    const expirable = getExpirableItem(name);
    if (!expirable) {
        return null;
    }
    if (!isPositionData(expirable)) {
        throw new TypeError("type mismatch");
    }
    return expirable.position;
}
