import classNames from "classnames";
import { missingMandatoryProp, missingProps } from "main/utils/errors";
import React, { useState } from "react";
import { StoreTypologyFilter } from "./_StoreType";
function StoreTypologyFilterVariant1({ storeTypes, setFilter, configuration }) {
    const defaultValue = configuration.translations.header.filters.allTypologies;
    const [selected, setSelected] = useState(defaultValue);
    const [isOpen, setOpen] = useState(false);
    function hanldeFilterClick(storeType) {
        setOpen(false);
        setSelected(storeType);
        const updatedStoreTypes = storeType === defaultValue ? storeTypes : [storeType];
        setFilter((prev) => ({
            ...prev,
            storeTypes: updatedStoreTypes,
        }));
    }
    return (React.createElement("div", { className: classNames("rt-filters-dropdown", {
            "rt-filters-dropdown--open": isOpen,
        }) },
        React.createElement("div", { className: "rt-filters-dropdown__selected-item", onClick: () => setOpen((prev) => !prev) }, selected),
        React.createElement("ul", { className: "rt-filters-dropdown__items" },
            React.createElement("li", { key: "__default-type", className: "rt-filters-dropdown__item", onClick: () => hanldeFilterClick(defaultValue) }, defaultValue),
            storeTypes.map((t) => (React.createElement("li", { key: t, className: "rt-filters-dropdown__item", onClick: () => hanldeFilterClick(t) }, t)))))
    // <select value={selected} onChange={hanldeFilterClick}>
    //     <option key="__default-type" className="rt-filters-dropdown__item">
    //         {defaultValue}
    //     </option>
    //     {storeTypes.map((t) => (
    //         <option key={t} className="rt-filters-dropdown__item" onClick={() => hanldeFilterClick(t)}>
    //             {t}
    //         </option>
    //     ))}
    // </select>
    );
}
function Component(props) {
    return React.createElement(StoreTypologyFilter, { StoreTypologyFiltersVariant: StoreTypologyFilterVariant1, ...props });
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => {
        const componentName = "StoreTypologyFilter (variant 1)";
        if (!props)
            throw new Error(missingProps(componentName));
        if (!props.allStores)
            throw new Error(missingMandatoryProp(componentName, "allStores"));
        if (!props.filter)
            throw new Error(missingMandatoryProp(componentName, "filter"));
        if (!props.setFilter)
            throw new Error(missingMandatoryProp(componentName, "setFilter"));
    },
};
