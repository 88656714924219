import classNames from "classnames";
import { scrollingHandler } from "core/events";
import React, { useEffect, useRef, useState } from "react";
export function Component() {
    const [isVisible, setVisible] = useState(false);
    const scrollingItemRef = useRef({ item: null });
    useEffect(() => {
        function handleScroll(e) {
            if (isVisible && e.detail.scrollingOffset === 0) {
                setVisible(false);
                return;
            }
            if (!isVisible) {
                scrollingItemRef.current.item = e.detail.scrollingItem;
                setVisible(true);
            }
        }
        scrollingHandler.addListener(handleScroll);
        return () => scrollingHandler.removeListener(handleScroll);
    }, [isVisible]);
    function handleScrollTop() {
        var _a;
        (_a = scrollingItemRef.current.item) === null || _a === void 0 ? void 0 : _a.scroll({
            top: 0,
            behavior: "smooth",
        });
    }
    return (React.createElement("img", { src: "/data/assets/top.svg", onClick: handleScrollTop, className: classNames("rt-scroll-top-button", {
            "rt-scroll-top-button--visible": isVisible,
        }) }));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
};
