import axios from "axios";
export async function validateHostname(key) {
    let response;
    try {
        response = await axios.post("https://endpoint.retailtune.com/storelocator-widget/get-hostname-from-key", JSON.stringify({ key }), {
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
    catch (e) {
        throw new Error(`Request failed. details: ${JSON.stringify(e)}`);
    }
    if (response.data.result !== "ok") {
        throw new Error(`Bad request. Endpoint status: "${response.data.result}". Check your API key.`);
    }
    return `https://${response.data.hostname}/`;
}
